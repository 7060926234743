export const normalizeCountry = (country, mapping) => {
	if (!country) return "international";

	const normalizedInput = country
		.toLowerCase()
		.trim()
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "") // Enlève les accents
		.replace(/ö/g, "o") // Remplace explicitement ö par o
		.replace(/ä/g, "a") // Remplace explicitement ä par a
		.replace(/ü/g, "u") // Remplace explicitement ü par u
		.replace(/ß/g, "ss") // Remplace explicitement ß par ss
		.replace(/\s+/g, "-") // Remplace les espaces par des tirets
		.replace(/[^a-z0-9-]/g, ""); // Enlève les caractères spéciaux restants

	return mapping[normalizedInput] || normalizedInput || "international";
};
