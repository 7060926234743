import anime from "../../libs/anime";
import globalVar from "../../utils/globalVar";

class AddToCartSticky {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.addToCartBtn = document.querySelector(".single_add_to_cart_button");
			cache.main = document.querySelector(".product-description");
			cache.lastScrollTop = 0;
			cache.isStickyVisible = false;
		};

		let registerEvents = () => {
			window.addEventListener("scroll", scrollingSticky);
		};

		let scrollingSticky = () => {
			if (globalVar.tablet.matches && cache.addToCartBtn && cache.main) {
				const scrollTop =
					window.pageYOffset || document.documentElement.scrollTop;
				const triggerPoint =
					cache.main.offsetTop +
					cache.main.offsetHeight +
					window.innerHeight * 2;

				// Si 2 scrolls apres le cache.main, on affiche le sticky
				if (scrollTop >= triggerPoint) {
					if (!cache.isStickyVisible) {
						showStickyButton();
						cache.isStickyVisible = true;
					}
				} else {
					if (cache.isStickyVisible) {
						hideStickyButton();
						cache.isStickyVisible = false;
					}
				}
				cache.lastScrollTop = scrollTop;
			}
		};

		let showStickyButton = () => {
			cache.addToCartBtn.classList.add("sticky-prep");

			setTimeout(() => {
				cache.addToCartBtn.classList.remove("sticky-prep");
				cache.addToCartBtn.classList.add("sticky-visible");
			}, 50);
		};

		let hideStickyButton = () => {
			cache.addToCartBtn.classList.remove("sticky-visible");
		};

		initFunc();
	}
}
export { AddToCartSticky };
