class TryInStore {
	constructor() {
		let cache = {};

		let initFunc = () => {
			initCache();
			registerEvents();
			observePopups();
		};

		let initCache = () => {
			cache.btnsSidebar = document.querySelectorAll("div.storepoint-btn");
			cache.containerForm = document.querySelector(".form-try-in-store");
			cache.form = document.querySelector(".form-try-in-store form");
			cache.inputNameDealer = document.querySelector("input[name='name-dealer']");
			cache.inputAddressDealer = document.querySelector("input[name='address-dealer']");
			cache.inputDealer = document.querySelector("input[name='email-dealer']");
			cache.inputBikeName = document.querySelector("input[name='name-bike']");
			cache.mapContainer = document.body;
			cache.unselectedCard = document.querySelector(".dealer-card.unselected");
			cache.selectedCard = document.querySelector(".dealer-card.selected");
			cache.selectedCardTitle = cache.selectedCard.querySelector(
				".dealer-card-title"
			);
			cache.selectedCardAddress = cache.selectedCard.querySelector(
				".dealer-card-address"
			);
			cache.bikeName = document.querySelector(".product_title");
			cache.nameSpan = document.querySelector(
				".popup-try-in-store .module-12-dealer-locator span.name"
			);
		};

		let registerEvents = () => {
			cache.mapContainer.addEventListener("click", (e) => {
				const button = e.target.closest("a.storepoint-popup-directions");
				if (button) {
					e.preventDefault();
					handleDealerClick(button);
				}
				
				if (cache.bikeName) {
					cache.nameSpan.textContent = cache.bikeName.textContent.trim();
					if (cache.inputBikeName) {
						cache.inputBikeName.value = cache.bikeName.textContent.trim();
					}
				}
			});

		};

		let observePopups = () => {
			const observer = new MutationObserver(() => {
				initCache();
			});

			observer.observe(document.body, { childList: true, subtree: true });
		};

		let handleDealerClick = (button) => {
			const popup = button.closest(".storepoint-location-popup");
			if (popup) {
				const titleElement = popup.querySelector(
					".storepoint-location-popup b"
				);
				const emailElement = popup.querySelector("a.storepoint-popup-email");
				const addressElement = popup.querySelector("p.street-address");
				const email = emailElement ? emailElement.textContent.trim() : null;
				const name = titleElement ? titleElement.textContent.trim() : null;
				const address = addressElement ? addressElement.textContent.trim() : null;

				if (name && cache.inputNameDealer) {
					cache.inputNameDealer.value = name;
				}

				if (address && cache.inputAddressDealer) {
					cache.inputAddressDealer.value = address;
				}

				if (email && cache.inputDealer) {
					cache.inputDealer.value = email;
					if (cache.containerForm) {
						cache.containerForm.scrollIntoView({
							behavior: "smooth",
							block: "start",
						});
					}
				} else {
					console.warn("Aucun email trouvé dans cette card.");
				}

				if (cache.selectedCardTitle && titleElement) {
					cache.selectedCardTitle.textContent = titleElement.textContent.trim();
				}
				if (cache.selectedCardAddress && addressElement) {
					cache.selectedCardAddress.textContent = addressElement.textContent.trim();
				}

				if (cache.selectedCard) {
					cache.selectedCard.classList.add("show");
				}

				if (cache.unselectedCard) {
					cache.unselectedCard.classList.remove("show");
				}
			}
		};

		initFunc();
	}
}

export { TryInStore };
