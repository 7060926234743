import utils from "../utils/utils";
import anime from "../libs/anime";
import globalVar from "../utils/globalVar";

class Animations {
	constructor() {
		let scrollPos;
		const wh = window.innerHeight;
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			if (document.querySelector(".anime-title")) {
				cache.animeTitle = document.querySelectorAll(".anime-title");
				for (let i = 0; i < cache.animeTitle.length; i++) {
					cache.animeTitle[i].innerHTML = cache.animeTitle[
						i
					].textContent.replace(/(\S*)/g, (m) => {
						return (
							`<span class="word">` +
							m.replace(
								/(-|#|@)?\S(-|#|@)?/g,
								"<span class='letter'>$&</span>"
							) +
							`</span>`
						);
					});

					cache.animeTitle[i].style.opacity = 1;
				}
			}

			if (document.querySelector(".anime-texte")) {
				cache.animeTexte = document.querySelectorAll(".anime-texte");
			}

			if (document.querySelector(".anime-cards")) {
				cache.animeCards = document.querySelectorAll(".anime-cards");
			}

			if (document.querySelector(".anime-btns")) {
				cache.animeBtns = document.querySelectorAll(".anime-btns");
			}
		};

		let registerEvents = () => {
			if (cache.animeTitle || cache.animeTexte || cache.animeCards) {
				window.addEventListener("scroll", scrollDetect);
				scrollDetect();
			}
		};

		let scrollDetect = function() {
			let up = false;
			if (scrollPos > window.pageYOffset) {
				up = true;
			}
			scrollPos = window.pageYOffset;

			if (cache.animeTitle) {
				for (let i = 0; i < cache.animeTitle.length; i++) {
					const title = cache.animeTitle[i];
					const title_pos = utils.getOffsetTop(title);
					const letter = title.querySelectorAll(".letter");
					
					const isHeroModule = title.closest('.module-01-hero') !== null;
					
					let heroSubtitle = null;
					if (isHeroModule) {
						const wrapperTitle = title.closest('.wrapper-title');
						if (wrapperTitle) {
							heroSubtitle = wrapperTitle.querySelector('.anime-texte');
						}
					}

					if (
						scrollPos + wh > title_pos + title.offsetHeight / 2 &&
						scrollPos < title_pos + wh &&
						!title.classList.contains("anime")
					) {
						title.classList.add("anime");
						
						const letterAnimations = [];
						for (let ii = 0; ii < letter.length; ii++) {
							const animation = anime({
								targets: letter[ii],
								opacity: [0, 1],
								translateY: [-40, 0],
								delay: 30 * ii,
								duration: 500,
								easing: "easeInOutSine",
							});
							letterAnimations.push(animation);
						}
						

						if (isHeroModule && heroSubtitle) {
							// Calcul la durée totale de l'animation du titre
							const totalDuration = 500 + (30 * (letter.length - 1));
							
							// Animer le sous-titre après l'animation du titre
							setTimeout(() => {
								if (!heroSubtitle.classList.contains("anime")) {
									heroSubtitle.classList.add("anime");
									anime({
										targets: heroSubtitle,
										opacity: [0, 1],
										translateY: [50, 0],
										duration: 400,
										easing: "easeOutSine",
									});
								}
							}, totalDuration + 100);
							
							heroSubtitle.dataset.animatedByTitle = 'true';
						}
					}
				}
			}

			if (cache.animeTexte) {
				for (let i = 0; i < cache.animeTexte.length; i++) {
					const txt = cache.animeTexte[i];
					
					// Ne pas animer si déjà animé par le titre dans le module hero
					if (txt.dataset.animatedByTitle === 'true') {
						continue;
					}
					
					const txt_pos = utils.getOffsetTop(txt);

					if (
						scrollPos + (wh - 100) > txt_pos &&
						scrollPos < txt_pos + wh &&
						!txt.classList.contains("anime")
					) {
						txt.classList.add("anime");
						anime({
							targets: txt,
							opacity: [0, 1],
							translateY: [50, 0],
							duration: 400,
							easing: "easeOutSine",
						});
					}
				}
			}

			if (cache.animeCards) {
				for (let i = 0; i < cache.animeCards.length; i++) {
					const cards = cache.animeCards[i];
					const cards_pos = utils.getOffsetTop(cards);

					if (
						scrollPos + (wh - 100) > cards_pos &&
						scrollPos < cards_pos + wh &&
						!cards.classList.contains("anime")
					) {
						cards.classList.add("anime");

						const children = cards.children;

						anime({
							targets: children,
							opacity: [0, 1],
							duration: 400,
							delay: anime.stagger(200, { easing: "easeOutQuad" }),
							easing: "easeOutSine",
						});
					}
				}
			}

			if (cache.animeBtns?.length > 0) {
				cache.buttons = [];
				cache.animeBtns.forEach((btnWrapper) => {
					if (!btnWrapper.classList.contains("anime")) {
						const buttons = btnWrapper.querySelectorAll(".btn");
						cache.buttons.push(...buttons);
			
						btnWrapper.classList.add("anime");
			
						if (cache.buttons.length > 0) {
							setTimeout(() => {
								anime({
									targets: cache.buttons,
									opacity: [0, 1],
									duration: 800,
									easing: "easeOutQuad",
									delay: anime.stagger(200),
								});
							}, 1000);
						}
					}
				});
			}
		};

		initFunc();
	}
}
export { Animations };
