class OutOfStock {
	constructor() {
		let cache = {};
		let initFunc = () => {
			if (yubabikes_vars.is_online == "yes") {
                initCache();
                registerEvents();
				
            } 
			else {
                disableButtonForOfflineProduct();
            }
		};

		let initCache = () => {
			cache.variationsForm = document.querySelector("form.variations_form");
			cache.variationsWrapper = document.querySelector(
				".woocommerce-variation-add-to-cart"
			);
			cache.addToCartButton = document.querySelector(".single_add_to_cart_button");
		};

		let registerEvents = () => {
			if (cache.variationsForm && cache.variationsWrapper && cache.addToCartButton) {
				// Surveille les changements de classe dynamiques sur le wrapper
				const observer = new MutationObserver(() => {
					updateButtonText();
				});

				observer.observe(cache.variationsWrapper, { attributes: true, attributeFilter: ['class'] });

				// Mise à jour initiale
				updateButtonText();
			}
		};

		let updateButtonText = () => {
			if (cache.variationsWrapper.classList.contains("woocommerce-variation-add-to-cart-enabled")) {
				cache.addToCartButton.textContent = yubabikes_vars.add_to_cart;
				cache.addToCartButton.disabled = false;
			} else {
				cache.addToCartButton.textContent = yubabikes_vars.out_of_stock;
				cache.addToCartButton.disabled = true;
			}
		};

        const disableButtonForOfflineProduct = () => {
            const button = document.querySelector(".single_add_to_cart_button");
            if (button) {
                button.textContent = yubabikes_vars.not_available_online;
                button.disabled = true;
            }
        };

		initFunc();
	}
}

export { OutOfStock };