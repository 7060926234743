import globalVar from "../../../utils/globalVar";

class InfoProductMobile {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.title = document.querySelector(".product_title.entry-title");
			cache.subtitle = document.querySelector(".subtitle");
			cache.price = document.querySelector(".price");
			cache.gallery = document.querySelector(".gallery-mobile");
			cache.galleryDesktop = document.querySelector(".template-single-spare-parts .gallery-desktop");
		};

		let registerEvents = () => {
			rearrangeElements();

			window.addEventListener("resize", function() {
				rearrangeElements();
			});
		};

		let rearrangeElements = () => {
			if (globalVar.tablet.matches) {
				let existingContainer = document.querySelector(".product-description-mobile");
		
				if (!existingContainer) {
					let tempContainer = document.createElement("div");
					tempContainer.classList.add("product-description-mobile");
		
					if (cache.title) tempContainer.appendChild(cache.title);
					if (cache.subtitle) tempContainer.appendChild(cache.subtitle);
					if (cache.price) tempContainer.appendChild(cache.price);
		
					if (cache.galleryDesktop && cache.galleryDesktop.parentNode) {
						cache.galleryDesktop.parentNode.insertBefore(tempContainer, cache.galleryDesktop);
					} else if (cache.gallery && cache.gallery.parentNode) {
						cache.gallery.parentNode.insertBefore(tempContainer, cache.gallery);
					}
				}
			}
		};

		initFunc();
	}
}
export { InfoProductMobile };
