import anime from "../../libs/anime";
import utils from "../../utils/utils";

class MiniCart {
	constructor() {
		let cache = {};
		let cartData = window.initialCartData || null;

		let initFunc = () => {
			initCache();
			registerEvents();

			if (cartData) {
				updateCartUI(cartData);
			} else {
				preloadCartData();
			}
		};

		let initCache = () => {
			cache.cartButton = document.getElementById("cart-button");
			cache.cartButtonMobile = document.getElementById("cart-button-mobile");
			cache.miniCartWrapper = document.getElementById("mini-cart-wrapper");
			cache.miniCart = document.getElementById("mini-cart");
			cache.closeCartButton = document.getElementById("close-mini-cart");
			cache.miniCartItems = document.querySelector(".mini-cart-items");
		};

		let preloadCartData = () => {
			loadMiniCart(true);
		};

		let showLoadingState = () => {
			if (cache.miniCartItems) {
				cache.miniCartItems.classList.add("mini-cart-loading");
				cache.miniCartItems.innerHTML = '<div class="loading-spinner"></div>';
			}
		};

		let hideLoadingState = () => {
			if (cache.miniCartItems) {
				cache.miniCartItems.classList.remove("mini-cart-loading");
			}
		};

		let openMiniCart = () => {
			cache.miniCartWrapper.style.visibility = "visible";
			cache.miniCartWrapper.classList.remove("hidden");

			anime({
				targets: ".filter",
				opacity: [0, 1],
				duration: 400,
				easing: "easeOutQuad",
			});

			anime({
				targets: "#mini-cart",
				translateX: ["100%", "0%"],
				duration: 500,
				easing: "easeOutQuad",
				begin: function() {
					cache.miniCart.classList.remove("hidden");
					utils.disableScroll();
				},
			});

			anime({
				targets: ".wrapper-recommended-products",
				translateX: ["200%", "0%"],
				opacity: [0, 1],
				duration: 500,
				delay: 250,
				easing: "easeOutQuad",
				begin: function() {
					document
						.querySelector(".wrapper-recommended-products")
						.classList.remove("hidden");
				},
			});
		};

		let closeMiniCart = () => {
			anime({
				targets: ".filter",
				opacity: [1, 0],
				duration: 400,
				easing: "easeInQuad",
			});

			anime({
				targets: ".wrapper-recommended-products",
				translateX: ["0%", "200%"],
				opacity: [1, 0],
				duration: 500,
				easing: "easeInQuad",
			});

			anime({
				targets: "#mini-cart",
				translateX: ["0%", "100%"],
				duration: 500,
				delay: 200,
				easing: "easeInQuad",
				complete: function() {
					cache.miniCartWrapper.style.visibility = "hidden";
					cache.miniCartWrapper.classList.add("hidden");
					cache.miniCart.classList.add("hidden");
					document
						.querySelector(".wrapper-recommended-products")
						.classList.add("hidden");
					utils.enableScroll();
				},
			});
		};

		let registerEvents = () => {
			if (cache.cartButton) {
				cache.cartButton.addEventListener("click", function() {
					openMiniCart();

					if (cartData) {
						updateCartUI(cartData);
					} else {
						showLoadingState();
					}

					loadMiniCart(false);
				});
			}

			if (cache.cartButtonMobile) {
				cache.cartButtonMobile.addEventListener("click", function() {
					openMiniCart();

					if (cartData) {
						updateCartUI(cartData);
					} else {
						showLoadingState();
					}

					loadMiniCart(false);
				});
			}

			cache.closeCartButton.addEventListener("click", function() {
				closeMiniCart();
			});

			document.addEventListener("click", function(event) {
				if (event.target.closest(".increment")) {
					const input = event.target
						.closest(".custom-quantity")
						.querySelector("input.qty");
					input.stepUp();
					input.dispatchEvent(new Event("change"));
				} else if (event.target.closest(".decrement")) {
					const input = event.target
						.closest(".custom-quantity")
						.querySelector("input.qty");
					input.stepDown();
					input.dispatchEvent(new Event("change"));
				}

				if (event.target.closest(".btn-remove")) {
					event.preventDefault();
					const removeButton = event.target.closest(".btn-remove");
					const cartKey = removeButton.dataset.cart_key;

					// Animation de suppression avant la requête AJAX
					const itemElement = removeButton.closest(".mini-cart-item");

					anime({
						targets: itemElement,
						opacity: [1, 0],
						height: [itemElement.offsetHeight, 0],
						marginTop: [itemElement.style.marginTop, 0],
						marginBottom: [itemElement.style.marginBottom, 0],
						duration: 300,
						easing: "easeOutQuad",
						complete: function() {
							fetch(wc_cart_params.ajax_url, {
								method: "POST",
								headers: {
									"Content-Type": "application/x-www-form-urlencoded",
								},
								body: "action=remove_cart_item&cart_key=" + cartKey,
							})
								.then((response) => response.json())
								.then((data) => {
									if (data.success) {
										// Met à jour le compteur du panier
										document
											.querySelectorAll("#cart-button .items")
											.forEach((counter) => {
												counter.textContent = data.item_count;
											});

										// Met à jour le compteur et le sous-total
										const itemCountElement = document.querySelector(
											"#mini-cart-item-count"
										);
										if (itemCountElement) {
											itemCountElement.textContent = data.item_count;
										}

										// Met à jour le sous-total
										const subtotalElement = document.querySelector(
											".woocommerce-Price-amount.amount.subtotal"
										);
										if (subtotalElement) {
											subtotalElement.innerHTML = data.subtotal;
										}

										// Si le panier est vide, recharger tout le contenu
										if (data.item_count === 0) {
											loadMiniCart();
										}
									}
								})
								.catch((error) => {
									console.error("Erreur:", error);
									loadMiniCart();
								});
						},
					});
				}
			});
		};

		let loadMiniCart = (isPreload = false) => {
			if (!isPreload) {
				showLoadingState();
			}

			fetch(wc_cart_params.ajax_url, {
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: "action=get_mini_cart_content",
			})
				.then((response) => response.json())
				.then((data) => {
					cartData = data;

					if (!isPreload) {
						updateCartUI(data);
					}
					hideLoadingState();
				})
				.catch((error) => {
					console.error("Erreur:", error);
					hideLoadingState();
				});
		};

		let updateCartUI = (data) => {
			if (cache.miniCartItems) {
				cache.miniCartItems.innerHTML = data.content;
			}

			const itemCountElement = document.querySelector("#mini-cart-item-count");
			if (itemCountElement) {
				itemCountElement.textContent = data.item_count;
			}

			attachQuantityChangeListeners();
		};

		let attachQuantityChangeListeners = () => {
			document
				.querySelectorAll(".mini-cart-quantity input.qty")
				.forEach(function(input) {
					input.addEventListener("change", function() {
						let cartKey = this.name.match(/\[([a-z0-9]+)\]/)[1];
						let quantity = parseInt(this.value, 10);

						updateMiniCartItem(cartKey, quantity);
					});
				});
		};

		let updateMiniCartItem = (cartKey, quantity) => {
			showLoadingState();

			const formData = new FormData();
			formData.append("action", "update_cart_item");
			formData.append("cart_key", cartKey);
			formData.append("quantity", quantity);

			fetch(wc_cart_params.ajax_url, {
				method: "POST",
				body: new URLSearchParams(formData),
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.success) {
						// Met à jour tout le contenu du mini-cart
						if (cache.miniCartItems) {
							cache.miniCartItems.innerHTML = data.content;
						}

						// Met à jour le compteur dans le header
						document.querySelectorAll(".items").forEach((counter) => {
							counter.textContent = data.item_count;
						});

						// Met à jour le sous-total
						const subtotalElement = document.querySelector(
							".mini-cart-subtotal .woocommerce-Price-amount.amount.subtotal"
						);
						if (subtotalElement && data.cart_subtotal) {
							subtotalElement.innerHTML = data.cart_subtotal;
						}

						// Mise à jour du compteur dans le mini-cart
						const itemCountElement = document.querySelector(
							"#mini-cart-item-count"
						);
						if (itemCountElement) {
							itemCountElement.textContent = data.item_count;
						}

						attachQuantityChangeListeners();
					}
					hideLoadingState();
				})
				.catch((error) => {
					console.error("Erreur lors de la mise à jour:", error);
					hideLoadingState();
					loadMiniCart();
				});
		};

		initFunc();
	}
}

export { MiniCart };
