import anime from "../libs/anime";
import globalVar from "../utils/globalVar";

class Nav {
	constructor() {
		let prevTab;
		let cache = {};
		let isNavActive = false;
		let isMouseOverNav = false;
		let debounceTimeout;
		let isSearchActive = false;
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.nav = document.querySelector(".nav-mobile");
			cache.wrapperNavMob = document.querySelector(".wrapper-nav-mobile");
			cache.menu_tab = document.querySelectorAll(".menu_tab");
			cache.sub_menu = document.querySelectorAll(".sub_menu");
			cache.navTriggerDesk = document.querySelectorAll(
				"header .wrapper-nav .top-title"
			);
			cache.navTriggerDeskSearch = document.querySelector(
				"header .wrapper-nav .btn-search"
			);
			cache.navTriggerMobSearch = document.querySelector(
				"header .wrapper-nav-mobile .btn-search"
			);
			cache.navCloseDesk = document.querySelector(
				"header .nav_desktop .btn_close"
			);
			cache.navDesk = document.querySelector("header .nav_desktop");
			cache.navFilter = document.querySelector("header .filter");
			cache.navmobileBtn = document.querySelector(".nav_mobile-btn");
			cache.navMobileBtnClose = document.querySelectorAll(
				".btn-close-nav-mobile"
			);
			cache.header = document.querySelector(".site-header");

			cache.navTriggerMob = document.querySelectorAll(
				"header .nav_mobile .top-title"
			);
			cache.navBackMob = document.querySelectorAll(
				"header .nav_mobile .menu_tab .back"
			);

			cache.navMob = document.querySelector("header .nav_mobile");

			cache.categoryItems = document.querySelectorAll(".category-item");
			cache.contentItems = document.querySelectorAll(".content-item");
			cache.searchInput = document.querySelector(
				".menu_tab-search input[type='text']"
			);

			// Désactiver l'autocomplétion native
			if (cache.searchInput) {
				cache.searchInput.setAttribute("autocomplete", "off");
			}
		};

		let registerEvents = () => {
			cache.navTriggerDesk.forEach((trigger) => {
				trigger.addEventListener("mouseenter", () => {
					isMouseOverNav = true;
					if (!isNavActive) openNav(trigger);
					switchTab(trigger);

					cache.navTriggerDesk.forEach((item) => {
						item.classList.remove("active");
					});

					trigger.classList.add("active");
				});

				trigger.addEventListener("click", function(event) {
					event.preventDefault();
					forceOpenNav(trigger);
				});
			});

			cache.navTriggerDeskSearch.addEventListener("click", function() {
				openNav(this);
			});

			cache.navDesk.addEventListener("mouseenter", () => {
				isMouseOverNav = true;
			});

			cache.navCloseDesk.addEventListener("click", closeNav);

			cache.navTriggerDesk.forEach((trigger) => {
				trigger.addEventListener("click", function(event) {
					event.preventDefault();
					forceOpenNav(trigger);
				});
			});

			cache.header.addEventListener("mouseleave", () => {
				if (cache.navDesk.querySelector(".menu_tab-search.active")) {
					return;
				}
				
				closeNav();
				clearNavState();
			});

			if (cache.searchInput) {
				cache.searchInput.addEventListener("focus", () => {
					isSearchActive = true;
				});

				cache.searchInput.addEventListener("blur", () => {
					isSearchActive = false;
				});
			}

			// Gestion des clics sur les catégories du nav_desktop
			for (let i = 0; i < cache.categoryItems.length; i++) {
				cache.categoryItems[i].addEventListener("click", handleCategoryClick);
			}

			// ===== Mobile ===== //
			for (let i = 0; i < cache.navTriggerMob.length; i++) {
				cache.navTriggerMob[i].addEventListener("click", openTabMobile);
			}
			for (let i = 0; i < cache.navBackMob.length; i++) {
				cache.navBackMob[i].addEventListener("click", closeTabMobile);
			}

			cache.navTriggerMobSearch.addEventListener("click", openSearchTabMobile);

			cache.navmobileBtn.addEventListener("click", toggleNav);

			for (let i = 0; i < cache.navMobileBtnClose.length; i++) {
				cache.navMobileBtnClose[i].addEventListener("click", closeNavMobile);
			}

			// Gestion des sous-catégories dans le menu
			cache.categoryItems.forEach((item) => {
				item.addEventListener("click", function() {
					const subTabId = this.getAttribute("data-sub-tab");
					showSubMenu(subTabId);
				});
			});
		};

		// DESKTOP
		let openNav = function(trigger) {
			const targetTabClass = trigger.dataset.tab;

			if (debounceTimeout) {
				clearTimeout(debounceTimeout);
			}

			debounceTimeout = setTimeout(() => {
				isMouseOverNav = true;

				if (!isNavActive) {
					isNavActive = true;

					cache.navDesk.style.display = "block";
					cache.navFilter.style.display = "block";

					document.querySelector("body").classList.add("overflow");
					document.querySelector("html").classList.add("overflow");

					anime({
						targets: cache.navDesk,
						opacity: [0, 1],
						duration: 200,
						easing: "easeOutQuad",
					});

					anime({
						targets: cache.navFilter,
						opacity: [0, 1],
						duration: 400,
						easing: "easeOutQuad",
					});
				}

				// Basculer les onglets de menu
				const activeTab = cache.navDesk.querySelector(".menu_tab.active");
				const targetTab = cache.navDesk.querySelector("." + targetTabClass);

				if (activeTab && activeTab !== targetTab) {
					activeTab.classList.remove("active");
					anime({
						targets: activeTab,
						opacity: [1, 0],
						duration: 100,
						easing: "easeOutQuad",
						complete: function() {
							activeTab.style.display = "none";
						},
					});
				}

				if (targetTab) {
					targetTab.style.display = "flex";
					targetTab.classList.add("active");

					anime({
						targets: targetTab,
						opacity: [0, 1],
						duration: 300,
						easing: "easeOutQuad",
					});
				}

				// Mettre à jour les états des triggers
				cache.navTriggerDesk.forEach((item) => {
					item.classList.remove("active");
				});
				trigger.classList.add("active");
			}, 200);
		};

		let forceOpenNav = function(trigger) {
			const targetTabClass = trigger.dataset.tab;
			isMouseOverNav = true;
			isNavActive = true;

			cache.navDesk.style.display = "block";
			cache.navFilter.style.display = "block";

			document.querySelector("body").classList.add("overflow");
			document.querySelector("html").classList.add("overflow");

			anime({
				targets: [cache.navDesk, cache.navFilter],
				opacity: [0, 1],
				duration: 200,
				easing: "easeOutQuad",
			});

			const targetTab = cache.navDesk.querySelector("." + targetTabClass);
			if (targetTab) {
				cache.menu_tab.forEach((tab) => {
					if (tab !== targetTab) {
						tab.style.display = "none";
						tab.classList.remove("active");
					}
				});

				targetTab.style.display = "flex";
				targetTab.classList.add("active");

				anime({
					targets: targetTab,
					opacity: [0, 1],
					duration: 300,
					easing: "easeOutQuad",
				});
			}

			cache.navTriggerDesk.forEach((item) => {
				item.classList.remove("active");
			});
			trigger.classList.add("active");
		};

		let closeNav = function() {
			if (debounceTimeout) {
				clearTimeout(debounceTimeout);
			}

			clearNavState();

			isMouseOverNav = false;
			isNavActive = false;

			cache.navTriggerDesk.forEach((trigger) => {
				trigger.classList.remove("active");
			});
			cache.navTriggerDeskSearch.classList.remove("active");

			cache.menu_tab.forEach((tab) => {
				tab.style.display = "none";
				tab.classList.remove("active");
			});

			anime({
				targets: cache.navDesk,
				opacity: [1, 0],
				duration: 400,
				easing: "easeOutQuad",
				complete: function() {
					cache.navDesk.style.display = "none";
				},
			});

			anime({
				targets: cache.navFilter,
				opacity: [1, 0],
				duration: 200,
				easing: "easeOutQuad",
				complete: function() {
					cache.navFilter.style.display = "none";
				},
			});

			document.querySelector("body").classList.remove("overflow");
			document.querySelector("html").classList.remove("overflow");
		};

		let clearNavState = () => {
			isMouseOverNav = false;
			isNavActive = false;

			cache.navTriggerDesk.forEach((trigger) => {
				trigger.classList.remove("active");
			});
			cache.navTriggerDeskSearch.classList.remove("active");

			cache.menu_tab.forEach((tab) => {
				tab.style.display = "none";
				tab.classList.remove("active");
			});

			cache.navDesk.style.display = "none";
			cache.navFilter.style.display = "none";

			document.querySelector("body").classList.remove("overflow");
			document.querySelector("html").classList.remove("overflow");

			if (debounceTimeout) {
				clearTimeout(debounceTimeout);
			}
		};

		let switchTab = function(trigger) {
			const targetTabClass = trigger.dataset.tab;
			const activeTab = cache.navDesk.querySelector(".menu_tab.active");
			const targetTab = cache.navDesk.querySelector(`.${targetTabClass}`);

			if (activeTab === targetTab) return;

			if (activeTab) {
				activeTab.classList.remove("active");
				anime({
					targets: activeTab,
					opacity: [1, 0],
					duration: 100,
					easing: "easeOutQuad",
					complete: function() {
						activeTab.style.display = "none";
					},
				});
			}

			if (targetTab) {
				targetTab.style.display = "flex";
				targetTab.classList.add("active");
				anime({
					targets: targetTab,
					opacity: [0, 1],
					duration: 100,
					easing: "easeOutQuad",
				});
			}

			cache.navTriggerDesk.forEach((item) => item.classList.remove("active"));
			trigger.classList.add("active");
		};

		let changeTabNav = function() {
			let activeTitle = document.querySelector(
				"header .wrapper-nav .top-title.active"
			);

			if (!activeTitle) {
				if (cache.navTriggerDeskSearch.classList.contains("active")) {
					activeTitle = cache.navTriggerDeskSearch;
					activeTitle.addEventListener("click", changeTabNav);
					activeTitle.removeEventListener("click", changeTabNavSearchOut);
				}
			} else {
				activeTitle.removeEventListener("click", closeNav);
				activeTitle.addEventListener("click", changeTabNav);
			}
			activeTitle.classList.remove("active");
			let activeTab = cache.navDesk.querySelector(
				"." + activeTitle.dataset.tab
			);
			let targetTitle = this;
			targetTitle.classList.add("active");
			if (targetTitle.classList.contains("search_btn")) {
				targetTitle.removeEventListener("click", changeTabNav);
				targetTitle.addEventListener("click", changeTabNavSearchOut);
			} else {
				targetTitle.removeEventListener("click", changeTabNav);
				prevTab = targetTitle;
			}
			let targetTab = cache.navDesk.querySelector(
				"." + targetTitle.dataset.tab
			);

			this.addEventListener("click", () => {
				if (this.classList.contains("active")) {
					targetTitle.removeEventListener("click", changeTabNav);
					targetTitle.addEventListener("click", openNav);
					closeNav();
				}
			});

			anime({
				targets: activeTab,
				opacity: [1, 0],
				duration: 300,
				easing: "easeOutQuad",
				complete: function() {
					activeTab.style.display = "none";
				},
			});

			targetTab.style.display = "flex";
			anime({
				targets: targetTab,
				opacity: [0, 1],
				duration: 300,
				easing: "easeOutQuad",
			});
		};

		let changeTabNavSearchOut = function() {
			if (prevTab && !prevTab.classList.contains("search_btn")) {
				let activeTitle = cache.navTriggerDeskSearch;
				activeTitle.removeEventListener("click", changeTabNavSearchOut);
				activeTitle.addEventListener("click", changeTabNav);
				activeTitle.classList.remove("active");
				let activeTab = cache.navDesk.querySelector(
					"." + activeTitle.dataset.tab
				);

				let targetTitle = prevTab;
				targetTitle.classList.add("active");
				targetTitle.removeEventListener("click", changeTabNav);
				let targetTab = cache.navDesk.querySelector(
					"." + targetTitle.dataset.tab
				);

				anime({
					targets: activeTab,
					opacity: [1, 0],
					duration: 300,
					easing: "easeOutQuad",
					complete: function() {
						activeTab.style.display = "none";
					},
				});

				targetTab.style.display = "flex";
				anime({
					targets: targetTab,
					opacity: [0, 1],
					duration: 300,
					easing: "easeOutQuad",
				});
			} else {
				closeNav();
			}
		};

		// Gestion du clic sur les catégories sur le nav_desktop
		let handleCategoryClick = function() {
			let categoryIndex = this.getAttribute("data-category-index");

			for (let i = 0; i < cache.categoryItems.length; i++) {
				cache.categoryItems[i].classList.remove("active");
			}

			this.classList.add("active");

			for (let i = 0; i < cache.contentItems.length; i++) {
				cache.contentItems[i].style.display = "none";
				anime({
					targets: cache.contentItems[i],
					opacity: [1, 0],
					duration: 300,
					easing: "easeOutQuad",
				});
			}

			let contentToShow = cache.navDesk.querySelector(
				'.content-item[data-category-index="' + categoryIndex + '"]'
			);

			contentToShow.style.display = "flex";
			anime({
				targets: contentToShow,
				opacity: [0, 1],
				duration: 300,
				easing: "easeOutQuad",
			});
		};

		let toggleNav = () => {
			if (cache.nav.classList.contains("active")) {
				closeNavMobile();
			} else {
				openNavMobile();
			}
		};

		// MOBILE
		let openNavMobile = () => {
			if (cache.navTriggerDeskSearch.classList.contains("active")) {
				closeNav();
			}
			cache.navmobileBtn.removeEventListener("click", openNav);
			cache.nav.classList.add("active");
			cache.navmobileBtn.classList.add("active");
			cache.navMob.style.display = "flex";
			cache.nav.style.display = "flex";
			document.querySelector("body").classList.add("overflow");
			document.querySelector("html").classList.add("overflow");
			anime({
				targets: cache.nav,
				opacity: [0, 1],
				translateX: [-250, 0],
				duration: 400,
				easing: "easeOutQuad",
				complete: function() {
					cache.navmobileBtn.addEventListener("click", closeNav);
				},
			});

			anime({
				targets: cache.wrapperNavMob,
				opacity: [1, 0],
				translateY: [0, -150],
				duration: 400,
				easing: "easeOutQuad",
				complete: function() {
					cache.wrapperNavMob.style.display = "none";
				},
			});
		};

		let closeNavMobile = () => {
			cache.navmobileBtn.removeEventListener("click", closeNav);
			document.querySelector("body").classList.remove("overflow");
			document.querySelector("html").classList.remove("overflow");
			anime({
				targets: cache.nav,
				opacity: [1, 0],
				translateX: [0, -250],
				duration: 400,
				easing: "easeOutQuad",
				complete: function() {
					cache.navmobileBtn.addEventListener("click", openNav);
					cache.nav.classList.remove("active");
					cache.navmobileBtn.classList.remove("active");
					cache.navMob.style.display = "none";
					cache.nav.style.display = "none";
				},
			});

			cache.menu_tab.forEach((tab) => {
				tab.classList.remove("open");
				anime({
					targets: tab,
					opacity: [1, 0],
					duration: 200,
					easing: "easeOutQuad",
					complete: function() {
						tab.style.display = "none";
					},
				});
			});

			cache.sub_menu.forEach((subMenu) => {
				subMenu.classList.remove("active");
				anime({
					targets: subMenu,
					opacity: [1, 0],
					duration: 200,
					easing: "easeOutQuad",
					complete: function() {
						subMenu.style.display = "none";
					},
				});
			});

			anime({
				targets: cache.wrapperNavMob,
				opacity: [0, 1],
				translateY: [-150, 0],
				duration: 400,
				easing: "easeOutQuad",
				complete: function() {
					cache.wrapperNavMob.style.display = "flex";
				},
			});
		};

		let openTabMobile = function() {
			let targetTab = cache.navMob.querySelector("." + this.dataset.tab);
			targetTab.style.display = "flex";
			targetTab.classList.add("open");

			anime({
				targets: targetTab,
				opacity: [0, 1],
				translateX: ["-50%", "0%"],
				duration: 300,
				easing: "easeOutQuad",
			});
		};

		let closeTabMobile = function() {
			let targetTab = this.closest(".menu_tab");

			anime({
				targets: targetTab,
				opacity: [1, 0],
				translateX: ["0%", "-50%"],
				duration: 300,
				easing: "easeOutQuad",
				complete: function() {
					targetTab.classList.remove("open");
					targetTab.style.display = "none";
				},
			});
		};

		// Affiche le sous-menu d'une catégorie spécifique
		let showSubMenu = function(subTabId) {
			let targetTab = cache.navMob.querySelector(`.${subTabId}`);

			if (!targetTab) {
				return;
			}

			cache.navMob.querySelectorAll(".menu_tab").forEach((tab) => {
				let mainContent = tab.querySelector(":scope > .main-content");
				if (mainContent) {
					anime({
						targets: mainContent,
						opacity: [1, 0],
						translateX: [0, "-100%"],
						duration: 300,
						easing: "easeOutQuad",
						complete: function() {
							mainContent.style.display = "none";
						},
					});
				}
			});

			// Affiche le sous-menu cible
			targetTab.style.display = "flex";
			anime({
				targets: targetTab,
				opacity: [0, 1],
				translateX: ["-100%", "0%"],
				duration: 300,
				easing: "easeOutQuad",
			});
		};

		let openSearchTabMobile = () => {
			let searchTab = cache.navMob.querySelector(".menu_tab-search");

			// Ferme d'autres onglets ouverts
			cache.navMob
				.querySelectorAll(".menu_tab:not(.menu_tab-search)")
				.forEach((tab) => {
					anime({
						targets: tab,
						opacity: [1, 0],
						translateX: [0, "-100%"],
						duration: 300,
						easing: "easeOutQuad",
						complete: function() {
							tab.style.display = "none";
						},
					});
				});

			// Affiche le menu de recherche
			cache.navMob.style.display = "flex";
			searchTab.style.display = "flex";

			anime({
				targets: searchTab,
				opacity: [0, 1],
				translateX: ["100%", "0%"],
				duration: 300,
				easing: "easeOutQuad",
			});

			anime({
				targets: cache.wrapperNavMob,
				opacity: [1, 0],
				translateY: [0, -150],
				duration: 400,
				easing: "easeOutQuad",
				complete: function() {
					cache.wrapperNavMob.style.display = "none";
				},
			});

			const closeBtn = searchTab.querySelector(".btn_close_search");
			if (closeBtn) {
				closeBtn.addEventListener("click", closeSearchTabMobile);
			}
		};

		let closeSearchTabMobile = () => {
			let searchTab = cache.navMob.querySelector(".menu_tab-search");

			anime({
				targets: searchTab,
				opacity: [1, 0],
				translateX: ["0%", "100%"],
				duration: 300,
				easing: "easeOutQuad",
				complete: function() {
					searchTab.style.display = "none";
				},
			});

			anime({
				targets: cache.wrapperNavMob,
				opacity: [0, 1],
				translateY: [-150, 0],
				duration: 400,
				easing: "easeOutQuad",
				complete: function() {
					cache.wrapperNavMob.style.display = "flex";
				},
			});
		};

		initFunc();
	}
}

export { Nav };
