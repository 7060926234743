class Module01 {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.videoElements = document.querySelectorAll(".video-element");
			cache.fallbackImages = document.querySelectorAll(".fallback-image");
		};

		let registerEvents = () => {
            
			cache.videoElements.forEach((video, index) => {
				if (video.tagName === "VIDEO") {
					video.load();
					video.addEventListener("loadeddata", () => {
						if (cache.fallbackImages[index]) {
							cache.fallbackImages[index].style.opacity = "0";
						}
						video.style.opacity = "1";
					});
				}

				if (video.tagName === "IFRAME") {
                    let iframeTimeout = setTimeout(() => {
                        if (cache.fallbackImages[index]) {
                            cache.fallbackImages[index].style.opacity = "0";
                        }
                        video.style.opacity = "1";
                    }, 700);

                    video.addEventListener("load", () => {
                        clearTimeout(iframeTimeout);
                        if (cache.fallbackImages[index]) {
                            cache.fallbackImages[index].style.opacity = "0";
                        }
                        video.style.opacity = "1";
                    });

                    // API Vimeo si disponible
                    if (typeof Vimeo !== 'undefined' && video.src.includes("vimeo.com")) {
                        const player = new Vimeo.Player(video);

                        player.on("loaded", () => {
                            clearTimeout(iframeTimeout);
                            if (cache.fallbackImages[index]) {
                                cache.fallbackImages[index].style.opacity = "0";
                            }
                            video.style.opacity = "1";
                        });
                    }
                }
			});
		};

		initFunc();
	}
}

export { Module01 };
