// Components
import { Header } from "./components/header";
import { Nav } from "./components/nav";
import { PopUp } from "./components/popup";
import { PopupAlert } from "./components/popupAlert";
import { Slider } from "./components/slider";
import { SliderOtherProducts } from "./components/sliderOtherProducts";
import { SliderHomepage } from "./components/sliderHomepage";
import { SliderQuotes } from "./components/sliderQuotes";
import { SliderBlog } from "./components/sliderBlog";
import { SliderGallery } from "./components/sliderGallery";
import { SliderProductGallery } from "./components/sliderProduct";
import { Toggle } from "./components/toggle";
import { BtnReadMore } from "./components/btnReadMore";
import { BtnShare } from "./components/btnShare";
import { Hotspots } from "./components/hotspots";
import { PopUpLocalisation } from "./components/popupLocalisation";
import { SearchForm } from "./components/searchForm";
import { Animations } from "./components/animations";

// Modules
import { Module01 } from "./modules/module-01-hero";
import { TryInStore } from "./modules/module-12-dealer-locator";
import { SidebarTabs } from "./modules/module-16-sidebar-tabs";
import { TabsBike } from "./modules/module-17-tabs-bike";
import { ToggleKeyFeatures } from "./modules/module-19-key-features";
import { RecommendedProducts } from "./modules/module-18-recommended-products";

// Pages
import { Home } from "./pages/home";
import { Blog } from "./pages/blog";

// Woocommerce
import { ColorsVariations } from "./woocommerce/pages/single-product/colorsVariations";
import { DropdownFilters } from "./woocommerce/components/dropdownFilters";
import { QuantityInputs } from "./woocommerce/components/quantityInputs";
import { CloseAlertButtons } from "./woocommerce/components/closeAlertButtons";
import { MiniCart } from "./woocommerce/components/miniCart";
import { ReadMore } from "./woocommerce/components/readMore";
import { FilterMob } from "./woocommerce/components/filterMob";
import { VariationsImg } from "./woocommerce/components/variationsImg";
import { LoginTabs } from "./woocommerce/pages/myaccount/loginTabs";
import { DynamicSKU } from "./woocommerce/components/dynamicSKU";
import { VariationsSelector } from "./woocommerce/components/variationsSelector";
import { AddToCartSticky } from "./woocommerce/components/addToCartSticky";
import { InfoProductMobile } from "./woocommerce/pages/single-product/infoProductMobile";
import { Lightbox } from "./woocommerce/pages/single-product/lightbox";
import { OutOfStock } from "./woocommerce/components/outOfStock";

class App {
	constructor() {
		const header = new Header();
		const nav = new Nav();
		const animations = new Animations;

		if (document.querySelector(".template-home")) {
			const home = new Home();
		}

		if (document.querySelector(".popup_click")) {
			const popUp = new PopUp();
		}

		if (document.querySelector(".popup-alert")) {
			const popupAlert = new PopupAlert();
		}

		if (document.querySelector("#searchform")) {
			const searchForm = new SearchForm();
		}

		if (document.querySelector(".slider-blog-posts")) {
			const sliderBlog = new SliderBlog();
		}

		if (document.querySelector(".slider-homepage")) {
			const sliderHomepage = new SliderHomepage();
		}

		if (document.querySelector(".slider-quotes")) {
			const sliderQuotes = new SliderQuotes();
		}

		if (document.querySelector(".slider-gallery")) {
			const sliderGallery = new SliderGallery();
		}

		if (document.querySelector(".slider-product-gallery")) {
			const sliderProductGallery = new SliderProductGallery();
		}

		if (document.querySelector(".slider-other-products")) {
			const sliderOtherProducts = new SliderOtherProducts();
		}

		if (
			document.querySelector(".swiper") &&
			!document.querySelector(".slider-homepage") &&
			!document.querySelector(".slider-quotes") &&
			!document.querySelector(".slider-blog-posts") &&
			!document.querySelector(".slider-gallery") &&
			!document.querySelector(".slider-product-gallery") &&
			!document.querySelector(".slider-other-products")
		) {
			const slider = new Slider();
		}

		if (document.querySelector(".template-blog")) {
			const blog = new Blog();
		}

		if (document.querySelector(".key-tabs")) {
			const toggleKeyTabs = new ToggleKeyFeatures(".key-tabs");
		}

		if (document.querySelector(".faqs")) {
			const toggleFaqs = new Toggle(".faqs", "answer");
		}

		if (document.querySelector(".module-01-hero.layout-video")) {
			const module01 = new Module01();
		}
		
		if (document.querySelector(".wrapper-try-in-store .module-12-dealer-locator")) {
			const tryInStore = new TryInStore();
		}

		if (document.querySelector(".module-16-sidebar-tab")) {
			const sidebarTabs = new SidebarTabs();
		}

		if (document.querySelector(".module-17-tabs-bike")) {
			const tabsBike = new TabsBike();
		}

		if (document.querySelector(".module-18-recommended-products")) {
			const recommendedProducts = new RecommendedProducts();
		}

		if (document.querySelector(".module-19-key-features")) {
			const toggleKeyFeatures = new ToggleKeyFeatures();
		}

		if (document.querySelector(".btn-language")) {
			const popupLocalisation = new PopUpLocalisation();
		}

		if (document.querySelector(".module-20-tech-specs")) {
			const btnReadMore = new BtnReadMore();
		}

		if (document.querySelector(".btn-share")) {
			const btnShare = new BtnShare();
		}

		if (document.querySelector(".hotspots")) {
			const hotspots = new Hotspots();
		}
	
		// ==== WooCommerce ===== //

		if (document.querySelector(".template-cpt-product")) {
			const colorsVariations = new ColorsVariations();
		}

		if (document.querySelector(".filter-group")) {
			const dropdownFilters = new DropdownFilters();
		}

		if (document.querySelector(".custom-quantity")) {
			const quantityInputs = new QuantityInputs();
		}

		if (
			document.querySelector(".woocommerce-message") ||
			document.querySelector(".woocommerce-error") ||
			document.querySelector(".woocommerce-info")
		) {
			const closeAlertButtons = new CloseAlertButtons();
		}

		if (document.querySelector("#cart-button")) {
			const miniCart = new MiniCart();
		}

		if (document.querySelector(".filter-mobile")) {
			const filterMob = new FilterMob();
		}

		if (document.querySelector("#customer_login")) {
			const loginTabs = new LoginTabs();
		}

		if (document.querySelector(".template-cpt-product")) {
			const infoProductMobile = new InfoProductMobile();
		}

		if (document.querySelector(".template-cpt-product")) {
			const lightbox = new Lightbox();
		}

		if (document.querySelector(".template-cpt-product .description-wrapper")) {
			const readMore = new ReadMore();
		}

		if (document.querySelector(".template-cpt-product .variations_form")) {
			const variationsImg = new VariationsImg();
		}

		if (document.querySelector(".template-cpt-product .variations_form")) {
			const dynamicSKU = new DynamicSKU();
		}

		// En attendant la validation
		// if (document.querySelector(".variations_form.cart")) {
			// const variationsSelector = new VariationsSelector();
		// }

		if (document.querySelector(".single_add_to_cart_button")) {
			const addToCartSticky = new AddToCartSticky();
		}

		if (document.querySelector(".woocommerce-variation-add-to-cart")) {
			const outOfStock = new OutOfStock();
		}
	}
}

window.onload = function() {
	let app = new App();
};
