class Blog {
    constructor() {
        let cache = {};
        let currentCategorySlug = '';
        
        let initFunc = () => {
            console.log("Initialisation du module Blog");
            initCache();
            detectCurrentCategory();
            registerEvents();
        };

        let initCache = () => {
            cache.loadMoreBtn = document.getElementById("load-more");
            cache.postsContainer = document.getElementById("blog-posts");
            cache.filterButtons = document.querySelectorAll(".btn-filter");
        };
        
        // Détecter la catégorie actuelle depuis l'URL
        let detectCurrentCategory = () => {
            const activeFilterBtn = document.querySelector(".btn-filter.active");
            if (activeFilterBtn) {
                currentCategorySlug = activeFilterBtn.getAttribute("data-category-slug");
            }
            
            const urlPath = window.location.pathname;
            if (urlPath.includes('/category/')) {
                const categorySlugMatch = urlPath.match(/\/category\/([^\/]+)/);
                if (categorySlugMatch && categorySlugMatch[1]) {
                    currentCategorySlug = categorySlugMatch[1];
                }
            }
            
            if (currentCategorySlug && cache.loadMoreBtn) {
                cache.loadMoreBtn.setAttribute("data-current-category", currentCategorySlug);
            }
        };

        let registerEvents = () => {
            if (!cache.postsContainer) {
                return;
            }

            if (cache.loadMoreBtn) {
                cache.loadMoreBtn.addEventListener("click", () => {
                    let page = parseInt(cache.loadMoreBtn.getAttribute("data-page")) + 1;
                    
                    cache.loadMoreBtn.setAttribute("data-page", page);
                    
                    // Récupérer la catégorie actuelle si elle existe
                    const categorySlug = cache.loadMoreBtn.getAttribute("data-current-category") || currentCategorySlug || '';

                    const xhr = new XMLHttpRequest();
                    xhr.open("POST", ajax_object.ajax_url, true);
                    xhr.setRequestHeader(
                        "Content-Type",
                        "application/x-www-form-urlencoded; charset=UTF-8"
                    );

                    xhr.onload = () => {
                        if (xhr.status === 200) {
                            try {
                                const response = JSON.parse(xhr.responseText);
                                
                                if (response.success) {
                                    cache.postsContainer.insertAdjacentHTML("beforeend", response.data);
                                    
                                    if (!response.has_more_posts) {
                                        cache.loadMoreBtn.style.display = "none";
                                    }
                                }
                            } catch (e) {
                                console.error("Erreur lors du parsing de la réponse:", e);
                                console.log("Réponse texte:", xhr.responseText);
                            }
                        } else {
                            console.error("Erreur HTTP:", xhr.status);
                        }
                    };
                    
                    xhr.onerror = () => {
                        console.error("Erreur de connexion lors de la requête AJAX");
                    };

                    let params = `action=load_more_posts&page=${page}`;
                    
                    // Ajouter la catégorie si elle existe
                    if (categorySlug) {
                        params += `&category_slug=${encodeURIComponent(categorySlug)}`;
                        params += `&data-current-category=${encodeURIComponent(categorySlug)}`;
                    }
                    
                    xhr.send(params);
                });
            }

            cache.filterButtons.forEach((button) => {
                button.addEventListener("click", function() {
                    const categoryID = this.getAttribute("data-category");
                    const categorySlug = this.getAttribute("data-category-slug") || '';
                    
                    console.log("Filtre cliqué - ID:", categoryID, "Slug:", categorySlug);
                    
                    currentCategorySlug = categorySlug;
                    
                    // Mettre à jour l'attribut data-current-category du bouton load-more
                    if (cache.loadMoreBtn) {
                        cache.loadMoreBtn.setAttribute("data-current-category", categorySlug);
                        cache.loadMoreBtn.setAttribute("data-page", "1");
                        cache.loadMoreBtn.style.display = "inline-block";
                    }

                    cache.postsContainer.innerHTML = "";

                    const xhr = new XMLHttpRequest();
                    xhr.open("POST", ajax_object.ajax_url, true);
                    xhr.setRequestHeader(
                        "Content-Type",
                        "application/x-www-form-urlencoded; charset=UTF-8"
                    );

                    xhr.onload = function() {
                        if (xhr.status === 200) {
                            try {
                                const response = JSON.parse(xhr.responseText);
                                
                                if (response.success) {
                                    cache.postsContainer.innerHTML = response.data;
                                } else {
                                    cache.postsContainer.innerHTML = "<p>No posts found</p>";
                                }
                            } catch (e) {
                                console.error("Erreur lors du parsing de la réponse:", e);
                            }
                        } else {
                            console.error("Erreur HTTP:", xhr.status);
                        }
                    };
                    
                    xhr.onerror = function() {
                        console.error("Erreur de connexion lors de la requête AJAX");
                    };

                    const timestamp = new Date().getTime();
                    let params = `action=filter_posts_by_category&category_id=${categoryID}&_=${timestamp}`;
                    
                    if (categorySlug) {
                        params += `&category_slug=${encodeURIComponent(categorySlug)}`;
                    }
                    
                    xhr.send(params);
                });
            });
        };

        initFunc();
    }
}
export { Blog };
