class Lightbox {
	constructor() {
		let initFunc = () => {
			registerEvents();
		};

		let registerEvents = () => {
			jQuery(document).ready(function($) {
				$('[data-magnific="gallery"]').magnificPopup({
					type: "image",
					gallery: {
						enabled: true,
					},
				});

				$('[data-magnific="single-image"]').magnificPopup({
					type: "image",
					gallery: {
						enabled: false,
					},
				});
			});
		};

		initFunc();
	}
}
export { Lightbox };
