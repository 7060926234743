export const countryLanguages = {
	france: ["fr", "en"],
	belgium: ["fr", "en"],
	luxembourg: ["fr", "en"],
	germany: ["de"],
	austria: ["de"],
	italy: ["it"],
	usa: ["en"],
	canada: ["en"],
	international: ["en"],
	netherlands: ["en"],
	spain: ["en"],
	"czech-republic": ["en"],
	denmark: ["en"],
	estonia: ["en"],
	hungary: ["en"],
	ireland: ["en"],
	poland: ["en"],
	portugal: ["en"],
	slovakia: ["en"],
	slovenia: ["en"],
	croatia: ["en"],
	latvia: ["en"],
	lithuania: ["en"],
	sweden: ["en"],
	bulgaria: ["en"],
	cyprus: ["en"],
	finland: ["en"],
	greece: ["en"],
	malta: ["en"],
	romania: ["en"],
};

export const countryMapping = {
	belgique: "belgium",
	belgië: "belgium",
	belgien: "belgium",
	belgium: "belgium",
	osterreich: "austria",
	oesterreich: "austria",
	österreich: "austria",
	austria: "austria",
	autriche: "austria",
	deutschland: "germany",
	allemagne: "germany",
	germany: "germany",
	france: "france",
	frankreich: "france",
	"united states": "usa",
	"united-states": "usa",
	"united states of america": "usa",
	"états-unis": "usa",
	"etats-unis": "usa",
	usa: "usa",
	us: "usa",
};

// Domaines spéciaux
export const specialDomains = {
	italy: "yubabikes.it",
	usa: "yubabikes.com",
	canada: "yubabikes.com",
};

// Chemins européens
export const euPaths = {
	france: "/fr",
	belgium: "/fr",
	luxembourg: "/fr",
	germany: "/de",
	austria: "/de",
};
