import anime from "../libs/anime";
import globalVar from "../utils/globalVar";

class SidebarTabs {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
      handleUrlHash();
    };

    let initCache = () => {
      cache.tabLinks = document.querySelectorAll(".title-anchor");
      cache.tabs = document.querySelectorAll(".tabs-item");
      cache.urlHash = window.location.hash;
    };

    let registerEvents = () => {
      // Par défaut, affiche le premier tab et active le premier lien
      cache.tabs[0].classList.add("active");
      cache.tabLinks[0].classList.add("active");

      cache.tabLinks.forEach((link) => {
        link.addEventListener("click", (e) => {
          e.preventDefault();
          const targetTitle = link.getAttribute("data-title");

          updateActiveTabs(targetTitle);
          link.classList.add("active");
        });
      });
    };

    let updateActiveTabs = (targetTitle) => {
      // Masquer tous les onglets et désactiver les liens actifs
      cache.tabs.forEach((tab) => {
        tab.classList.toggle(
          "active",
          tab.querySelector(`h2[data-title="${targetTitle}"]`)
        );
      });

      cache.tabLinks.forEach((tabLink) => {
        tabLink.classList.remove("active");
      });
    };

    let handleUrlHash = () => {
      if (cache.urlHash) {
        const targetButton = document.querySelector(
          `a.title-anchor[href="${cache.urlHash}"]`
        );
    
        if (targetButton) {
          const targetTitle = targetButton.getAttribute("data-title");
    
          cache.tabLinks.forEach((link) => link.classList.remove("active"));
          cache.tabs.forEach((tab) => tab.classList.remove("active"));
    
          targetButton.classList.add("active");
          cache.tabs.forEach((tab) => {
            if (tab.querySelector(`h2[data-title="${targetTitle}"]`)) {
              tab.classList.add("active");
    
              const parentModule = tab.closest(".module-16-sidebar-tab");
    
              if (parentModule) {
                const headerOffset = 100;
                const rect = parentModule.getBoundingClientRect();
              
                setTimeout(() => {
                  window.scrollTo({
                    top: window.scrollY + rect.top - headerOffset,
                    behavior: "smooth",
                  });
                }, 300);
              }
            }
          });
        }
      }
    };

    initFunc();
  }
}

export { SidebarTabs };