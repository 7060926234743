import globalVar from "../utils/globalVar";

class Header {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.header = document.querySelector("header.site-header");
			cache.popupAlert = document.querySelector(".popup-alert");
			cache.breadcrumb = document.querySelector(".breadcrumb");
			cache.lastScrollTop = 0;
		};

		let registerEvents = () => {
			if (cache.popupAlert) {
				adjustHeaderTop();
			}

			window.addEventListener("resize", adjustHeaderTop);

			window.addEventListener("scroll", scrollingWheel);
		};

		let adjustHeaderTop = () => {
			if (globalVar.tablet.matches && cache.popupAlert) {
				cache.header.style.top = "55px";
				if (cache.breadcrumb) {
					cache.breadcrumb.style.top = "130px";
				}
			} else if (cache.popupAlert) {
				cache.header.style.top = "45px";
			} else {
				cache.header.style.top = "";
			}
		};

		let scrollingWheel = () => {
			const scrollTop =
				window.pageYOffset || document.documentElement.scrollTop;
			const viewportHeight = window.innerHeight;

			// Toujours afficher le header si le scroll est au-dessus de 50% de la hauteur de l'écran
			if (scrollTop < viewportHeight * 0.5) {
				cache.header.style.transform = "translateY(0)";
				if (!cache.popupAlert) {
					cache.header.style.top = globalVar.tablet.matches ? "20px" : "45px";
				} else {
					cache.header.style.top =
						cache.popupAlert && cache.popupAlert.classList.contains("close")
							? "20px"
							: globalVar.tablet.matches
							? "55px"
							: "45px";
					cache.popupAlert.style.transform = globalVar.tablet.matches
						? "translateY(-55px)"
						: "translateY(-45px)";
				}

				cache.lastScrollTop = scrollTop;
				return;
			}

			// En dessous de 50% du viewport
			if (cache.popupAlert && cache.popupAlert.classList.contains("close")) {
				cache.header.style.top = "20px";
			} else if (cache.popupAlert) {
				cache.header.style.top = globalVar.tablet.matches ? "55px" : "45px";
			} else {
				cache.header.style.top = globalVar.tablet.matches ? "20px" : "45px";
			}

			if (globalVar.tablet.matches) {
				if (scrollTop > cache.lastScrollTop) {
					cache.header.style.transform = "translateY(-270%)";
					if (cache.popupAlert) {
						cache.popupAlert.style.transform = "translateY(-55px)";
					}
				} else {
					cache.header.style.transform = "translateY(0)";
					if (cache.popupAlert) {
						cache.popupAlert.style.transform = "translateY(-55px)";
					}
				}
			} else {
				if (scrollTop > cache.lastScrollTop) {
					cache.header.style.transform = "translateY(-155%)";
					if (cache.popupAlert) {
						cache.popupAlert.style.transform = "translateY(-45px)";
					}
				} else {
					cache.header.style.transform = "translateY(0)";
					if (cache.popupAlert) {
						cache.popupAlert.style.transform = "translateY(-45px)";
					}
				}
			}

			cache.lastScrollTop = scrollTop;
		};

		initFunc();
	}
}
export { Header };
