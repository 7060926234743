class PopupAlert {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.header = document.querySelector("header.site-header");
			cache.navMobile = document.querySelector(".nav_mobile");
			cache.popupAlert = document.querySelector(".popup-alert");
			cache.closePopupAlert = document.getElementById("close-popup-alert");
		};

		let registerEvents = () => {
			if (cache.closePopupAlert) {
				cache.closePopupAlert.addEventListener("click", closePopupAlert);
			}
		};

		let closePopupAlert = () => {
			if (cache.popupAlert.classList.contains("open")) {
				cache.popupAlert.classList.remove("open");
				cache.popupAlert.classList.add("close");
                cache.header.style.top = "20px";
				cache.navMobile.style.height = `${window.innerHeight + 40}px`;
			} else {
				cache.popupAlert.classList.add("open");
			}
		};

		initFunc();
	}
}
export { PopupAlert };



