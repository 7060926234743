import {
	countryLanguages,
	countryMapping,
	specialDomains,
	euPaths,
} from "../config/countries";
import { cookieUtils } from "../utils/cookies";
import { normalizeCountry } from "../utils/countryUtils";

class PopUpLocalisation {
	constructor() {
		let cache = {};

		let initCache = () => {
			cache.btnLanguage = document.querySelectorAll(".btn-language");
			cache.popupLocalisation = document.querySelector(".popup-localisation");
			cache.closePopupLocalisation = document.getElementById(
				"close-popup-localisation"
			);

			cache.submitPopup = document.querySelector(".popup-localisation .btn");
			cache.languageSelect = document.getElementById("language-select");
			cache.countrySelect = document.getElementById("country-select");
		};

		let initFunc = () => {
			initCache();
			// handleInitialRedirection();
			registerEvents();
			if (!shouldShowPopup()) {
				if (cache.popupLocalisation) {
					cache.popupLocalisation.classList.remove("open");
				}
				return;
			}
		};

		let registerEvents = () => {
			const hasValidCookies =
				cookieUtils.get("popupValidated") &&
				cookieUtils.get("currentCountry") &&
				cookieUtils.get("currentLanguage");

			if (!hasValidCookies) {
				setTimeout(() => {
					if (cache.popupLocalisation) {
						cache.popupLocalisation.classList.add("open");
					}
				}, 500);
			}

			if (cache.closeBtn) {
				cache.closeBtn.addEventListener("click", () => {
					cache.closePopupLocalisation.classList.remove("open");
				});
			}

			if (cache.closePopupLocalisation) {
				cache.closePopupLocalisation.addEventListener(
					"click",
					closePopupLocalisation
				);
			}

			if (cache.btnLanguage) {
				cache.btnLanguage.forEach((btn) => {
					btn.addEventListener("click", () => {
						cookieUtils.delete("popupValidated");
						if (cache.popupLocalisation) {
							cache.popupLocalisation.classList.add("open");
						}
					});
				});
			}

			if (cache.submitPopup) {
				cache.submitPopup.addEventListener("click", applyChanges);
			}

			if (cache.countrySelect) {
				cache.countrySelect.addEventListener("change", updateLanguageOptions);
			}
		};

		const handleInitialRedirection = () => {
			const savedCountry = cookieUtils.get("currentCountry");
			const savedLanguage = cookieUtils.get("currentLanguage");
			const popupValidated = cookieUtils.get("popupValidated");

			// Si le cookie existe, redirige si nécessaire
			if (savedCountry && savedLanguage && popupValidated === "true") {
				if (checkAndRedirectBasedOnCookies()) {
					return;
				}
				return;
			}

			const detectedCountry = normalizeCountry(
				window.geotCountry,
				countryMapping
			);
			const currentDomain = window.location.hostname;
			const currentPath = window.location.pathname;

			// Vérification des domaines spéciaux
			if (specialDomains[detectedCountry]) {
				const targetDomain = specialDomains[detectedCountry];
				if (!currentDomain.includes(targetDomain)) {
					const protocol = window.location.protocol;
					const targetUrl = `${protocol}//${targetDomain}`;
					window.location.href = targetUrl;
					return;
				}
			}
			// Ensuite vérifie les chemins européens
			else if (euPaths[detectedCountry]) {
				const requiredPath = euPaths[detectedCountry];

				// Vérifie si nous sommes déjà sur le bon chemin linguistique
				const isOnCorrectPath = currentPath.startsWith(requiredPath);

				if (!isOnCorrectPath) {
					const newUrl = `${window.location.origin}${requiredPath}/`;
					window.location.href = newUrl;
					return;
				}
			}
			// Pour tous les autres pays
			else {
				if (currentPath !== "/" && !currentPath.startsWith("/en")) {
					window.location.href = `${window.location.origin}/`;
					return;
				}
			}

			// Si aucune redirection n'est nécessaire, initialiser les sélections
			initializeSelections();
		};

		const updateLanguageOptions = () => {
			if (!cache.countrySelect || !cache.languageSelect) return;

			const selectedCountry = cache.countrySelect.value;
			const availableLanguages = countryLanguages[selectedCountry] || ["en"];
			const currentLanguage = cache.languageSelect.value;

			cache.languageSelect.innerHTML = "";
			availableLanguages.forEach((lang) => {
				const option = document.createElement("option");
				option.value = lang;
				option.textContent =
					lang === "fr"
						? "French"
						: lang === "en"
						? "English"
						: lang === "de"
						? "German"
						: lang === "it"
						? "Italian"
						: lang;
				cache.languageSelect.appendChild(option);
			});

			if (availableLanguages.includes(currentLanguage)) {
				cache.languageSelect.value = currentLanguage;
			} else {
				cache.languageSelect.value = availableLanguages[0];
			}
		};

		const handleRedirection = (selectedCountry, selectedLanguage) => {
			const specialDomains = {
				italy: "https://yubabikes.it",
				usa: "https://yubabikes.com",
				canada: "https://yubabikes.com",
			};
		
			if (specialDomains[selectedCountry]) {
				window.location.href = specialDomains[selectedCountry];
				return;
			}
		
			const availableLanguages = countryLanguages[selectedCountry] || ["en"];
			let targetLanguage = availableLanguages.includes(selectedLanguage)
				? selectedLanguage
				: availableLanguages[0];
		
			const currentPath = window.location.pathname;
			const currentLanguage = getCurrentLanguage(currentPath);
		
			if (currentLanguage !== targetLanguage) {
				// MÉTHODE 1: Utiliser le sélecteur WPML existant dans le footer
				const wpmlLinks = document.querySelectorAll('.wpml-ls-sub-menu a.wpml-ls-link');
				let foundWpmlLink = false;
		
				wpmlLinks.forEach(link => {
					const linkLang = link.querySelector('.wpml-ls-native')?.getAttribute('lang');
					const linkText = link.querySelector('.wpml-ls-native')?.textContent.trim().toLowerCase();
					
					if ((linkLang && linkLang.toLowerCase() === targetLanguage.toLowerCase()) || 
						(linkText === targetLanguage.toLowerCase()) ||
						(linkText === 'en' && targetLanguage === 'en') ||
						(linkText === 'english' && targetLanguage === 'en') ||
						(linkText === 'fr' && targetLanguage === 'fr') ||
						(linkText === 'french' && targetLanguage === 'fr') ||
						(linkText === 'de' && targetLanguage === 'de') ||
						(linkText === 'german' && targetLanguage === 'de')) {
						
						window.location.href = link.href;
						foundWpmlLink = true;
						return;
					}
				});
		
				if (foundWpmlLink) return;
		
				// MÉTHODE 2: Utiliser l'API WPML si le sélecteur n'est pas disponible
				const currentUrl = window.location.pathname + window.location.search;
				fetch(`/wp-json/wpml/v1/translate-url`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						url: currentUrl,
						language: targetLanguage
					})
				})
				.then(response => response.json())
				.then(data => {
					// Vérifier différentes structures de réponse possibles
					if (data && data.translation) {
						window.location.href = data.translation;
					}
					else if (data && data.data && data.data.translation) {
						window.location.href = data.data.translation;
					}
					else {
						// MÉTHODE 3: Solution de secours - construction d'URL basique
						const pathSegments = currentPath.split('/').filter(Boolean);
						const isBlogPost = pathSegments.includes('blog');
						
						if (isBlogPost) {
							// Pour les articles de blog, rediriger vers la section blog
							window.location.href = `${window.location.origin}/${targetLanguage !== 'en' ? targetLanguage + '/' : ''}blog/`;
						} else if (pathSegments.some(segment => ['shop', 'boutique'].includes(segment))) {
							// Pour les pages de boutique
							const shopBase = targetLanguage === 'fr' ? 'boutique' : 'shop';
							const categorySlug = pathSegments[pathSegments.length - 1];
							window.location.href = `${window.location.origin}/${targetLanguage !== 'en' ? targetLanguage + '/' : ''}${shopBase}/${categorySlug}`;
						} else {
							// Pages standards
							const currentSlug = pathSegments[pathSegments.length - 1] || '';
							window.location.href = `${window.location.origin}/${targetLanguage !== 'en' ? targetLanguage + '/' : ''}${currentSlug}`;
						}
					}
				})
				.catch(error => {
					console.error("Error when fetching translation URL:", error);
					
					const languagePrefix = targetLanguage !== 'en' ? targetLanguage + '/' : '';
					window.location.href = `${window.location.origin}/${languagePrefix}`;
				});
			}
		};
		

		const applyChanges = () => {
			const selectedLanguage = cache.languageSelect?.value;
			const selectedCountry = cache.countrySelect?.value;

			if (!selectedCountry || !selectedLanguage) return;

			// Stockage dans les cookies
			cookieUtils.set("popupValidated", "true", 30);
			cookieUtils.set("popupLastShown", Date.now().toString(), 30);
			cookieUtils.set("currentCountry", selectedCountry, 30);
			cookieUtils.set("currentLanguage", selectedLanguage, 30);

			handleRedirection(selectedCountry, selectedLanguage);
		};

		const getCurrentLanguage = (path) => {
			if (path === "/" || path === "") return "en";
			if (path.startsWith("/de")) return "de";
			if (path.startsWith("/fr")) return "fr";
			return "en";
		};

		const checkAndRedirectBasedOnCookies = () => {
			const savedCountry = cookieUtils.get("currentCountry");
			const savedLanguage = cookieUtils.get("currentLanguage");
			const currentPath = window.location.pathname;
			const currentLanguage = getCurrentLanguage(currentPath);

			if (savedCountry && savedLanguage && currentLanguage !== savedLanguage) {
				// Vérifie si nous ne sommes pas déjà en train de rediriger
				const isRedirecting = sessionStorage.getItem("isRedirecting");
				if (isRedirecting) {
					sessionStorage.removeItem("isRedirecting");
					return false;
				}

				sessionStorage.setItem("isRedirecting", "true");
				handleRedirection(savedCountry, savedLanguage);
				return true;
			}
			return false;
		};

		const initializeSelections = () => {
			const detectedCountry = normalizeCountry(
				window.geotCountry,
				countryMapping
			);

			const defaultLanguageForCountry =
				countryLanguages[detectedCountry]?.[0] || "en";

			const currentLanguage =
				cookieUtils.get("currentLanguage") ||
				defaultLanguageForCountry ||
				window.geotLanguage ||
				"en";

			const currentCountry =
				cookieUtils.get("currentCountry") || detectedCountry || "international";

			if (cache.countrySelect) {
				cache.countrySelect.value = currentCountry;
			}

			updateLanguageOptions();

			if (cache.languageSelect) {
				cache.languageSelect.value = currentLanguage;
			}
		};

		let shouldShowPopup = () => {
			const popupValidated = cookieUtils.get("popupValidated");
			const lastShown = parseInt(cookieUtils.get("popupLastShown"), 10);
			const now = Date.now();
			const currentCountry = cookieUtils.get("currentCountry");
			const currentLanguage = cookieUtils.get("currentLanguage");

			if (popupValidated && currentCountry && currentLanguage) {
				if (lastShown && now - lastShown < 30 * 24 * 60 * 60 * 1000) {
					console.log(
						"Popup already validated and within 30 days. Skipping..."
					);
					return false;
				}
			}

			return true;
		};

		let closePopupLocalisation = () => {
			if (!cache.popupLocalisation) return;

			cache.popupLocalisation.classList.remove("open");

			const hasValidCookies =
				cookieUtils.get("popupValidated") &&
				cookieUtils.get("currentCountry") &&
				cookieUtils.get("currentLanguage");

			if (!hasValidCookies) {
				// Utilise la langue actuelle du site comme langue par défaut
				const currentPath = window.location.pathname;
				const currentLanguage = getCurrentLanguage(currentPath);

				const detectedCountry =
					normalizeCountry(window.geotCountry, countryMapping) ||
					"international";

				// Stockage dans les cookies
				cookieUtils.set("popupValidated", "true", 30);
				cookieUtils.set("popupLastShown", Date.now().toString(), 30);
				cookieUtils.set("currentCountry", detectedCountry, 30);
				cookieUtils.set("currentLanguage", currentLanguage, 30);
			}
		};

		initFunc();
	}
}

export { PopUpLocalisation };
